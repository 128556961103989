{
    "default": {
        "okta": {
            "clients": {
                "file_upload": "0oar4br5zfGd0FVnO1t7",
                "video_chat": "0oar4aydtepJDv9IW1t7"
            },
            "issuer": "https://ring.okta.com/oauth2/ausrhv2wyg1f2pWnl1t7"
        },
        "api_prefixes": {
            "file_upload": "https://fileupload.cs-tools.dev.ring.com",
            "video_chat": "https://videochat.cs-tools.dev.ring.com"
        }
    },
    "nxtools.cs-tools.dev.ring.com": {
        "okta": {
            "clients": {
                "file_upload": "0oar4br5zfGd0FVnO1t7",
                "video_chat": "0oar4aydtepJDv9IW1t7"
            },
            "issuer": "https://ring.okta.com/oauth2/ausrhv2wyg1f2pWnl1t7"
        },
        "api_prefixes": {
            "file_upload": "https://fileupload.cs-tools.dev.ring.com",
            "video_chat": "https://videochat.cs-tools.dev.ring.com"
        }
    },
    "nxtools.cs-tools.qa.ring.com": {
        "okta": {
            "clients": {
                "file_upload": "0oar4br5zfGd0FVnO1t7",
                "video_chat": "0oar4aydtepJDv9IW1t7"
            },
            "issuer": "https://ring.okta.com/oauth2/ausrhv2wyg1f2pWnl1t7"
        },
        "api_prefixes": {
            "file_upload": "https://fileupload.cs-tools.qa.ring.com",
            "video_chat": "https://videochat.cs-tools.qa.ring.com"
        }
    },
    "nxtools.cs-tools.ring.com": {
        "okta": {
            "clients": {
                "file_upload": "0oargm4s4lUg1RA9h1t7",
                "video_chat": "0oargm7rkmHk3oNVM1t7"
            },
            "issuer": "https://ring.okta.com/oauth2/ausrhv2wyg1f2pWnl1t7"
        },
        "api_prefixes": {
            "file_upload": "https://fileupload.cs-tools.ring.com",
            "video_chat": "https://videochat.cs-tools.ring.com"
        }
    }
}
