//Page
import React from 'react';

//Components
import { VideoChat } from '@amzn/ring-cs-tools-react-components';
import PageContent from '../components/layouts/PageContent';

function VideoChatPage() {

    return (
        <PageContent
            authenticated={true}
            expanded={true}
            clientKey='video_chat'
            clientName='Video Chat'
        >
            {<VideoChat
                contextPath='/video-chat'
                enableVideoSharing={false}
                showLocalVideo={false}
                consentWindow={false}
            />}
        </PageContent>
    );
}

export default VideoChatPage;
