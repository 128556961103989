//PAGE
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import PageContent from '../layouts/PageContent';
//API
import axios from 'axios';
import { oktaClients } from '../../util/Okta';
import settings from '../../settings.json';
//COMPONENTS
import { message } from 'antd';
//SEARCH PARAM
import { useSearchParams } from 'react-router-dom';

//get api prefix
const settingEnv = (settings as any)[window.location.host] || settings.default;
const apiPrefix = settingEnv.api_prefixes.file_upload;
const { useContinuousAuth } = oktaClients.file_upload;

export function FileViewer() {
    const [fileTitle, setFileTitle] = useState<string>('');
    const handleFileTitleChange = useCallback((title: string) => {
        setFileTitle(title);
    }, []);

    return (
        <PageContent
            authenticated={true}
            header='CS File Upload'
            headerColor='#0A405A'
            subheader={fileTitle}
            bodyColor='#fff'
            expanded={true}
            clientKey='file_upload'
            clientName='File Upload'
        >
            <AuthedFileViewer defineFileTitle={handleFileTitleChange} />
        </PageContent>
    );
}

const FileViewerContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-flow: column;
`;

const LoadingWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100vw;
    height: 100vh;
`;

const LoadingSpinner = styled.div`
    width: 48px;
    height: 48px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &::before {
        display: block;
        width: 40px;
        height: 40px;
        margin: 0 auto;
        border: 4px solid #1998d5;
        border-color: #ececec #ececec #1998d5 #ececec;
        border-radius: 50%;
        animation: ring-loader-animation 0.8s linear infinite;
        content: "";
    }

    @keyframes ring-loader-animation {
        0% {
        transform: rotate(0deg);
        }
        100% {
        transform: rotate(360deg);
        }
    }
`;

const LoadingText = styled.p`
`;

type DownloadFileRequest = {
    conversationId: string;
    uploadId: string;
    fileId?: string;
    image?: boolean;
};

type DownloadFileResponse = {
    downloadLink: string;
    fileName: string;
};

type Props = {
    defineFileTitle: (title: string) => void;
}

function AuthedFileViewer(props: Props) {

    const [searchParams] = useSearchParams();
    const fileId = useMemo(() => searchParams.get('fileId'), [searchParams]);
    const conversationId = useMemo(() => searchParams.get('conversationId'), [searchParams]);
    const uploadId = useMemo(() => searchParams.get('uploadId'), [searchParams]);
    const imageQueryParam = useMemo(() => searchParams.get('image'), [searchParams]);
    // Convert 'image' query parameter to boolean. If 'image' is the string 'true', set to true, else false.
    const image = imageQueryParam === 'true';

    const [downloading, setDownloading] = useState('Pending...');
    const [error, setError] = useState<string>('');

    const { getHeaders } = useContinuousAuth();

    const downloadFile = useCallback(async () => {
        if (!conversationId || !uploadId) return;

        // Download Fetch
        setDownloading('Downloading...');
        setError('');
        const headers = await getHeaders();
        const params: DownloadFileRequest = {
            fileId: fileId || undefined,
            conversationId,
            uploadId,
            image,
        };
        try {
            const uploadResult = await axios.get<DownloadFileResponse>(`${apiPrefix}/api/upload`, { headers, params });
            const fileLink = uploadResult.data.downloadLink;

            location.href = fileLink;
            return;
        } catch (e: any) {
            setError('Unable to retrieve file metadata');
            const errorMessage = e.response.data;
            message.error(errorMessage.error, 20);
        }
    }, [conversationId, uploadId, getHeaders]);

    useEffect(() => {
        downloadFile();
    }, [downloadFile]);

    if (downloading) {
        return (
            <LoadingWrapper>
                <LoadingSpinner />
                <LoadingText>{downloading}</LoadingText>
            </LoadingWrapper>
        );
    }

    if (error) {
        return (
            <p>{error}</p>
        );
    }

    return (
        <FileViewerContainer />
    );
}
