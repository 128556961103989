import React from 'react';
import { Route, Routes } from 'react-router-dom';
import 'antd/dist/reset.css';
import '../components/layouts/font-face.css';
import NeighborTools from './NeighborTools';
import VideoChatPage from './VideoChatPage';
import { FileViewer } from '../components/neighbor-tools/FileUploadViewer';

function App() {

	return (
		<Routes>
			<Route path='/neighbor-tools' element={<NeighborTools />} />
			<Route path='/video-chat/*' element={<VideoChatPage />} />
			<Route path='/file-viewer' element={<FileViewer />} />
		</Routes>
	);
}

export default App;
