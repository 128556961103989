//PAGE
import React from 'react';
import styled from 'styled-components';
import PageContent from '../components/layouts/PageContent';
import { FileUploadDetails } from '../components/neighbor-tools/FileUploadDetails';
import { VideoConferenceDetails } from '../components/neighbor-tools/VideoConferenceDetails';
//COMPONENTS
import { Collapse } from 'antd';
import { useSearchParams } from 'react-router-dom';

// Antd Components
const { Panel } = Collapse;

const StyledCollapse = styled(Collapse)`
    margin: 0px;

    .ant-collapse-content-box {
        padding: 0px !important;
    }
`;

// File Upload Details Page Content
function NeighborTools() {
    // url params
    const [searchParams] = useSearchParams();
    const showFileUpload = searchParams.get('showFileUpload');
    const showVideoChat = searchParams.get('showVideoChat');
    // url query param determining whether or not to show ImageConverter Download Button
    const showImageConverterParam = searchParams.get('showImageConverter');
    // If 'showImageConverter' is the string 'true', set to true, else false.
    const showImageConverter = showImageConverterParam === 'true';

    //page content
    return (
        <PageContent
            bodyColor='#fff'
            expanded
        >
            <StyledCollapse
                accordion
            >
                {showFileUpload && (
                    <Panel
                        header='File Upload'
                        key='showFileUpload'
                    >
                        <FileUploadDetails
                            showImageConverter={showImageConverter}
                        />
                    </Panel>
                )}
                {showVideoChat && (
                    <Panel
                        header='Video Chat'
                        key='showVideoChat'
                    >
                        <VideoConferenceDetails />
                    </Panel>
                )}
            </StyledCollapse>
        </PageContent>
    );
}

export default NeighborTools;