import React, { PropsWithChildren, ReactNode, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { oktaClients } from '../../util/Okta';
import OktaLogin from '../okta-auth/OktaLogin';
import PageHeader from './PageHeader';
import settings from '../../settings.json';

const settingEnv = (settings as any)[window.location.host] || settings.default;
const clientIds = settingEnv.okta.clients;

// Okta Auth
const defaultClientKey = 'file_upload';

//Styled Components
const Page = styled.main<{
    color: string;
}>`
    width: 100vw;
    height: 100vh;
    background-color: ${props => props.color};
    display: flex;
    flex-direction: column;
    overflow: scroll;
`;

const Content = styled.div<{
    color: string;
    expanded?: boolean;
}>`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    background-color: ${props => props.color};
    width: 100%;
    position: relative;

    ${props => !props.expanded && css`
        padding: 20px;
        max-width: 1400px;
        align-self: center;
    `}
`;

const Overlay = styled.div`
    position: absolute;
    z-index: 5;

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    background-color: #ffffffd0;
`;

const StyledFooter = styled.footer`
	text-align: center;
	padding: 1.5em;
	background: #f9f9f9;
	font-size: 14px;
    color: hsla(0, 0%, 0%, 0.5);
`;

type Props = PropsWithChildren<{
    bodyColor?: string;
    headerColor?: string;
    header?: string;
    subheader?: string;
    expanded?: boolean;
    footer?: boolean;
    authenticated?: boolean;
    clientKey?: string;
    clientName?: string;
}>;


type ContinuousAuthContentProps = PropsWithChildren<{
    clientKey: string;
    authOverlay: ReactNode;
}>

function ContinuousAuthContent(props: ContinuousAuthContentProps) {
    const {
        children,
        clientKey,
        authOverlay,
    } = props;

    const { useContinuousAuth } = oktaClients[clientKey];

    const {
        authState,
        isAuthenticated,
    } = useContinuousAuth();

    return (
        <>
            {!isAuthenticated && authOverlay}
            {authState && children}
        </>
    );
}

function PageContent(props: Props) {
    const {
        bodyColor = '#f9f9f9',
        headerColor,
        header,
        subheader,
        expanded,
        children,
        footer,
        authenticated,
        clientKey,
        clientName
    } = props;

    const { ContinuousAuthProvider } = oktaClients[clientKey || defaultClientKey];

    return (
        <Page color={bodyColor}>
            {header && (
                <PageHeader
                    headerColor={headerColor}
                    header={header}
                    subheader={subheader}
                />
            )}

            <Content color={bodyColor} expanded={expanded}>
                {authenticated ? (
                    <ContinuousAuthProvider>
                        <ContinuousAuthContent
                            clientKey={clientKey || defaultClientKey}
                            authOverlay={
                                <Overlay>
                                    <OktaLogin
                                        clientKey={clientKey || defaultClientKey}
                                        clientName={clientName || 'CS Tools'}
                                    />
                                </Overlay>
                            }>
                            {children}
                        </ContinuousAuthContent>
                    </ContinuousAuthProvider>
                ) : (
                    children
                )}
            </Content>

            {footer && (
                <StyledFooter>
                    © {new Date().getFullYear()}, Built by the Ring CS Engineering Team
                </StyledFooter>
            )}
        </Page>
    );
}

export default PageContent;