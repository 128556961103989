//PAGE
import React, { useState } from 'react';
import styled from 'styled-components';
import PageContent from '../layouts/PageContent';
//API
import axios from 'axios';
//AUTH
import { oktaClients } from '../../util/Okta';
import settings from '../../settings.json';
//COMPONENTS
import { Button, Input, message } from 'antd';
import { PlayCircleOutlined, UserOutlined, VideoCameraAddOutlined } from '@ant-design/icons';
//SEARCH PARAM
import { useSearchParams } from 'react-router-dom';

//get api prefix
const settingEnv = (settings)[window.location.host] || settings.default;
const apiPrefix = settingEnv.api_prefixes.video_chat;
const { useContinuousAuth } = oktaClients.video_chat;

export function VideoConferenceDetails() {
    return (
        <PageContent
            authenticated={true}
            bodyColor='#fff'
            expanded={true}
            clientKey='video_chat'
            clientName='Video Chat'
        >
            <AuthedVideoConferenceDetails />
        </PageContent>
    );
}

//styled components
const VideoConferenceBody = styled.div`
    margin: 0px 0px 40px 0px;
    padding: 10px;
    max-width: 370px;
`;
const VideoConferenceText = styled.p`
    font-size: 10px;
    margin: 5px 0px;
`;
const VideoConferenceWrapper = styled.div`
`;

// File Upload Details Page Content
function AuthedVideoConferenceDetails() {

    // url
    const [joinUrl, setJoinUrl] = useState('');
    const [joinToggle, setJoinToggle] = useState(false);
    // loading
    const [loading, setLoading] = useState(false);
    // params
    const [label, setLabel] = useState('Click the button below to begin a Video Conference!');
    // url params
    const [searchParams] = useSearchParams();
    const agentDisplayName = searchParams.get('agentDisplayName') || 'Agent';
    const conversationId = searchParams.get('conversationId');
    const conversationStatus = searchParams.get('status');
    const customerId = searchParams.get('customerId');

    const { getHeaders } = useContinuousAuth();

    const createMeeting = async () => {
        setLoading(true);
        const headers = await getHeaders();
        const payload = {
            customerId,
            conversationId,
            agentDisplayName,
        };
        try {
            const { data } = await axios.post(apiPrefix + '/api/create-chime-meeting', payload, { headers });
            setJoinUrl(data.joinUrl);
            setJoinToggle(true);
            data.joinUrl && setLabel('Created! Click the button below to join');
        } catch (e) {
            message.error('There was a problem creating your Request, conversation status is not Open');
            console.error('creation error', e.message);
        } finally {
            setLoading(false);
        }
    };

    const joinMeeting = async () => {
        window.open(joinUrl);
        setJoinToggle(false);
        setLabel('Click the button below to create a NEW Video Conference')
    }

    //page content
    return (
        <VideoConferenceWrapper>
            {(conversationId && conversationStatus === 'open') ?
                <VideoConferenceBody>
                    {/* Refresh List Component*/}
                    <VideoConferenceText>
                        Neighbor Associated:
                    </VideoConferenceText>
                    <Input
                        placeholder='Neighbor Display Name'
                        prefix={<UserOutlined />}
                        value='Neighbor'
                        style={{
                            width: '250px',
                            marginBottom: '15px'
                        }}
                        disabled='true'
                    >
                    </Input>
                    <VideoConferenceText>
                        {label}
                    </VideoConferenceText>
                    {joinToggle ?
                        <Button
                            onClick={joinMeeting}
                            icon={<VideoCameraAddOutlined />}
                            target='_blank'
                            type="primary"
                        >
                            Click to Join
                        </Button> :
                        <Button
                            type="primary"
                            loading={loading}
                            onClick={createMeeting}
                            icon={<PlayCircleOutlined />}
                        >
                            Create Video Conference
                        </Button>
                    }
                </VideoConferenceBody> :
                <VideoConferenceText>
                    Video Chat is only available for Open Conversations.
                </VideoConferenceText>
            }
        </VideoConferenceWrapper>
    );
}
