import settings from '../settings.json';
import { OktaAuthReact } from '@amzn/ring-cs-okta-auth-client';

const settingEnv = (settings as any)[window.location.host] || settings.default;
const clientIds = settingEnv.okta.clients;

const oktaClients: { [key: string]: OktaAuthReact } = {};

for (const client in clientIds) {
    // Defining Issuer this way is a Temporary Implementation until all Okta Apps utilize same issuer for production.
    const iss = settingEnv.okta.issuer;
    oktaClients[client] = new OktaAuthReact(iss, clientIds[client]);
}

export { oktaClients };