//PAGE
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import PageContent from '../layouts/PageContent';
//API
import axios, { AxiosError } from 'axios';
import { oktaClients } from '../../util/Okta';
import settings from '../../settings.json';
//COMPONENTS
import { Button, Divider, message, Popover, Table } from 'antd';
import { CloudUploadOutlined, DownloadOutlined, ExclamationCircleOutlined, FileImageOutlined, FileSearchOutlined, LikeOutlined, ReloadOutlined, SendOutlined, MonitorOutlined } from '@ant-design/icons';
//SEARCH PARAM
import { useSearchParams } from 'react-router-dom';

//get api prefix
const settingEnv = (settings as any)[window.location.host] || settings.default;
const apiPrefix = settingEnv.api_prefixes.file_upload;
const { useContinuousAuth } = oktaClients.file_upload;

type FileUploadDetailsProps = {
    showImageConverter: boolean;
};

export function FileUploadDetails(props: FileUploadDetailsProps) {
    const { showImageConverter } = props;
    return (
        <PageContent
            authenticated={true}
            bodyColor='#fff'
            expanded={true}
            clientKey='file_upload'
            clientName='File Upload'
        >
            <AuthedFileUploadDetails
                showImageConverter={showImageConverter}
            />
        </PageContent>
    );
}

//styled components
const FileUploadBody = styled.div`
    margin: 0px 0px 40px 0px;
    padding: 10px;
    max-width: 370px;

    .ant-table-column-sort {
        background: #fff !important; //override default ANTD Table Column Sort background color
    }
`;
const RequestStatusCompleted = styled.div`
    align-items: center;
    border: 1px solid #40A142;
    border-radius: 20px;
    color: #40A142;
    display: flex;
    font-size: 13px;
    font-family: equipmedium;
    justify-content: center;
    margin: 5px 9px;
    padding: 5px 10px;
    width: fit-content;
`;
const RequestStatusFailed = styled.div`
    align-items: center;
    border: 1px solid red;
    border-radius: 20px;
    color: red;
    display: flex;
    font-size: 13px;
    font-family: equipmedium;
    justify-content: center;
    margin: 5px 9px;
    padding: 5px 10px;
    width: fit-content;
`;
const RequestStatusScanning = styled.div`
    align-items: center;
    border: 1px solid #1998d5;
    border-radius: 20px;
    color: #1998d5;
    display: flex;
    font-size: 13px;
    font-family: equipmedium;
    justify-content: center;
    margin: 5px 9px;
    padding: 5px 10px;
    width: fit-content;
`;
const RequestStatusUploading = styled.div`
    align-items: center;
    border: 1px solid #1998d5;
    border-radius: 20px;
    color: #1998d5;
    display: flex;
    font-size: 13px;
    font-family: equipmedium;
    justify-content: center;
    margin: 5px 9px;
    padding: 5px 10px;
    width: fit-content;
`;
const RequestStatusRequested = styled.div`
    align-items: center;
    border: 1px solid orange;
    border-radius: 20px;
    color: orange;
    display: flex;
    font-size: 13px;
    font-family: equipmedium;
    justify-content: center;
    margin: 5px 9px;
    padding: 5px 10px;
    width: fit-content;
`;
const FileDetailsReminder = styled.p`
    font-size: 10px;
    margin: 5px 0px;
`;

const TableRowDate = styled.p`
    margin: -10px 0px;
    font-size: 12px;
`;

const FileWrapper = styled.div`
`;
const ButtonWrapper = styled.div`
`;
const FileDetailsPopup = styled.div`
`;
const FileDetailsHeader = styled.div`
    color: #1890ff;
`;
const UploadDetailsValue = styled.div`
    font-size: 12px;
    font-family: 'equipmedium';
    margin: 0px auto 10px auto;
    width: 100%;
`;
const UploadDetailsLabel = styled.div`
    font-size: 12px;
    margin: auto;
    width: 100%;
`;

const OpenAttachmentText = styled.span`
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    whiteSpace: nowrap;
    width: 100px;
`;

type ListUploadsRequest = {
    caseId?: string;
    customerId?: string;
    conversationId?: string;
};

type FileEntry = {
    Name: string;
    FileId?: string;
};

type ListUploadEntry = {
    conversationId: string;
    uploadId: string;
    createdAt: string;
    expiresAt: string;
    status: string;
    fileName?: string; // legacy support
    files?: FileEntry[];
    conversionStatus?: string;
};

type ListUploadsResponse = ListUploadEntry[];

type CreateUploadRequest = {
    uploadId?: string; // resend a request
    caseId?: string;
    conversationId?: string;
};

type CreateUploadResponse = {
    uploadId: string;
};

type DownloadAttachmentButtonProps = {
    conversationId: string;
    conversionStatus: string;
    uploadId: string;
    fileName: string; // legacy support
    fileId?: string; // multi-file support
    multiFile: boolean;
    showImageConverter: boolean;
};

function DownloadAttachmentButton(props: DownloadAttachmentButtonProps) {
    const {
        conversationId,
        conversionStatus,
        uploadId,
        fileName,
        fileId,
        multiFile,
        showImageConverter
    } = props;

    const openFile = useCallback(async () => {
        const fileViewerUrl = new URL('/file-viewer', document.location.href);
        fileViewerUrl.searchParams.set('conversationId', conversationId);
        fileViewerUrl.searchParams.set('uploadId', uploadId);
        // multi file support
        if (fileId) {
            fileViewerUrl.searchParams.set('fileId', fileId);
        }
        open(fileViewerUrl, '_blank');
    }, [conversationId, uploadId, fileId]);

    // New Download Implementation (both options will only exist during testing)
    const convertFile = useCallback(async () => {
        const fileViewerUrl = new URL('/file-viewer', document.location.href);
        fileViewerUrl.searchParams.set('conversationId', conversationId);
        fileViewerUrl.searchParams.set('uploadId', uploadId);
        // multi file support
        if (fileId) {
            fileViewerUrl.searchParams.set('fileId', fileId);
        }
        // convert to image (boolean)
        fileViewerUrl.searchParams.set('image', 'true');
        open(fileViewerUrl, '_blank');
    }, [conversationId, uploadId, fileId]);

    // Converted File Support - Determine if the download button should be disabled
    const isDownloadDisabled = conversionStatus !== 'Completed';

    // Converted File Support - Customize Download Popup details based on File Conversion Status
    let conversionStatuses: Record<string, string> = {
        'N/A':
            'File Conversion Status is Unavailable',
        'Completed':
            fileName,
        'Pending':
            'File Conversion in Progress...',
        'FailedContentType':
            'Failed to Convert File - Incompatible Content Type',
        'FailedMaxPages':
            'Failed to Convert File - Max Page Limit Exceeded',
        'FailedPdfConversion':
            'Failed to Convert PDF File',
        'FailedJpgConversion':
            'Failed to Convert JPG File',
        'FailedPageCount':
            'Failed to Convert File - Page Count Exceeded',
        'FailedDownloadFile':
            'Failed to Download File',
        'FailedSaveFile':
            'Failed to Save File',
        'Failed':
            'Failed to Convert File'
    };

    // Change Converted Button Styling based on ConversionStatus
    const getConvertedButtonStyle = () => {
        const lowerCaseStatus = (conversionStatus as string).toLowerCase();
        if (conversionStatus === 'Completed') {
            return { border: '1px solid #40A142', color: '#40A142' };
        }
        if (conversionStatus === 'Pending') {
            return { border: '1px solid #ccc', color: '#ccc' };
        }
        if (lowerCaseStatus.includes('failed')) {
            return { border: '1px solid red', color: 'red' };
        }
        // Default style if none of the above conditions are met
        return { border: '1px solid #ccc', color: '#ccc' };
    };

    const convertedButtonStyle = getConvertedButtonStyle();

    // Button On-Hover Popup
    const fileDetailsPopup = (
        <FileDetailsPopup>
            <UploadDetailsLabel>
                File Name:
            </UploadDetailsLabel>
            <UploadDetailsValue>
                {fileName}
            </UploadDetailsValue>
        </FileDetailsPopup>
    );
    const convertedFileDetailsPopup = (
        <FileDetailsPopup>
            <UploadDetailsLabel>
                File Name (Converted):
            </UploadDetailsLabel>
            <UploadDetailsValue>
                {conversionStatuses[conversionStatus]}
            </UploadDetailsValue>
        </FileDetailsPopup>
    );
    const fileDetailsHeader = (
        <FileDetailsHeader>
            <FileImageOutlined style={{ marginRight: '5px' }} />
            Details
        </FileDetailsHeader>
    );

    return (
        <ButtonWrapper>
            {/* File Download Button */}
            {!showImageConverter &&
                <Popover content={fileDetailsPopup} title={fileDetailsHeader}>
                    <Button
                        type='default'
                        onClick={openFile}
                        icon={<DownloadOutlined style={{ verticalAlign: '0.125em' }} />}
                        style={{
                            border: '1px solid #40A142',
                            color: '#40A142',
                            display: 'block',
                            margin: multiFile ? '5px 0px' : '-5px 0px',
                            textAlign: 'left',
                            width: '150px',
                        }}
                    >
                        <OpenAttachmentText>
                            {fileName}
                        </OpenAttachmentText>
                    </Button>
                </Popover>
            }
            {/* Converted File Download Button */}
            {showImageConverter &&
                <Popover content={convertedFileDetailsPopup} title={fileDetailsHeader}>
                    <Button
                        type='default'
                        disabled={isDownloadDisabled}
                        onClick={convertFile}
                        icon={<DownloadOutlined style={{ verticalAlign: '0.125em' }} />}
                        style={{
                            ...convertedButtonStyle,
                            display: 'block',
                            margin: multiFile ? '5px 0px' : '-5px 0px',
                            textAlign: 'left',
                            width: '150px',
                        }}
                    >
                        <OpenAttachmentText>
                            Converted - {fileName}
                        </OpenAttachmentText>
                    </Button>
                </Popover>
            }
        </ButtonWrapper>
    );
}

// File Upload Details Page Content
function AuthedFileUploadDetails(props: FileUploadDetailsProps) {
    const { showImageConverter } = props;
    // url params
    const [searchParams] = useSearchParams();
    const caseId = searchParams.get('caseId');
    const conversationId = searchParams.get('conversationId');
    const conversationStatus = searchParams.get('status');
    const customerId = searchParams.get('customerId');
    // file details
    const [fileDetails, setFileDetails] = useState<ListUploadsResponse>();
    const [requestStatus, setRequestStatus] = useState('');
    // loading
    const [createLoading, setCreateLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [refreshLoading, setRefreshLoading] = useState(false);
    // upload details
    const [uploadId, setUploadId] = useState('');

    const { getHeaders } = useContinuousAuth();

    useEffect(() => {
        if (customerId) {
            listFileDetails(conversationId, '', customerId, null);
        }
        else if (caseId) {
            listFileDetails(null, '', null, caseId);
        }
    }, [caseId, customerId, conversationId]);

    const createUpload = async (caseId: string | null, conversationId: string | null, uploadId?: string) => {
        setLoading(true);
        const headers = await getHeaders();
        let payload: CreateUploadRequest = {};
        //set uploadId if it exists
        if (uploadId) {
            payload.uploadId = uploadId;
        }

        //set caseId for Salesforce integration or set conversationId for kustomer
        //check for caseId first since listUploads temporarily returns caseId as conversationId
        if (caseId) {
            payload.caseId = caseId;
        } else if (conversationId) {
            payload.conversationId = conversationId;
        }
        try {
            const { data } = await axios.post<CreateUploadResponse>(`${apiPrefix}/api/upload`, payload, { headers });
            setUploadId(data.uploadId);
            await listFileDetails(conversationId, data.uploadId, null, caseId);
        } catch (e: unknown) {
            if (e instanceof AxiosError && e.response) {
                const error = e.response.data.error;
                if (error.includes('Unable to send file upload link') || error.includes('Error calling webhook')) {
                    message.error('There was a problem creating your Request, email cannot be sent');
                }
                else if (error.includes('Case status is closed') || error.includes('Conversation status')) {
                    message.error('There was a problem creating your Request, status is invalid');
                }
                else if (error.includes('Invalid case ID') || error.includes('Error getting conversation')) {
                    message.error('There was a problem creating your Request, case or conversation does not exist');
                }
                else {
                    message.error('There was a problem creating your Request');
                }
                console.error('Request creation error', error);
            }
        } finally {
            setLoading(false);
            setCreateLoading(false);
        }
    };

    //list file details
    const listFileDetails = async (
        conversationId: string | null,
        uploadId: string,
        customerId: string | null,
        caseId: string | null,
    ) => {
        setLoading(true);
        let params: ListUploadsRequest = {};
        //set parameters for listUploads
        //check for caseId first since listUploads temporarily returns caseId as conversationId
        if (caseId) {
            params = { caseId };
        } else if (conversationId) {
            params = { conversationId };
        } else if (customerId) {
            params = { customerId };
        }

        const headers = await getHeaders();
        try {
            const { data } = await axios.get<ListUploadsResponse>(`${apiPrefix}/api/uploads`, { headers, params });

            if (data.length > 0) {
                const currentTime = new Date().getTime();
                // mark any expired uploads in requested, uploading, or scanning status as expired
                const processedData: ListUploadEntry[] = data.map((record) => {
                    if (currentTime > Number(record.expiresAt) && (record.status === 'requested' ||
                        record.status === 'Requested' || record.status === 'Scanning' || record.status === 'Uploading')) {
                        return {
                            ...record,
                            status: 'Expired'
                        }
                    }
                    return record;
                })

                // Set Current Request Status
                const currentRequest = processedData.find(request => request['uploadId'] === uploadId);
                setRequestStatus(currentRequest?.status || '');
                // Set File Details
                setFileDetails(processedData);
            }
        } catch (e: unknown) {
            if (e instanceof Error) {
                message.error('There was a problem retrieving file details');
                console.error('list files error', e.message);
            }
        } finally {
            setLoading(false);
            setRefreshLoading(false);
        }
    };

    // Customize Request Status based on File Upload Status
    let requestStatuses: Record<string, JSX.Element> = {
        'Completed':
            <RequestStatusCompleted>
                <LikeOutlined style={{ marginRight: '5px' }} />
                File Upload Complete
            </RequestStatusCompleted>,
        'Failed':
            <RequestStatusFailed>
                <ExclamationCircleOutlined style={{ marginRight: '5px' }} />
                Corrupt File
            </RequestStatusFailed>,
        'Scanning':
            <RequestStatusScanning>
                <MonitorOutlined style={{ marginRight: '5px' }} />
                Scanning File
            </RequestStatusScanning>,
        'Uploading':
            <RequestStatusUploading>
                <CloudUploadOutlined style={{ marginRight: '5px' }} />
                Received | Awaiting Neighbor Upload
            </RequestStatusUploading>,
        'Requested':
            <RequestStatusRequested>
                <SendOutlined style={{ marginRight: '5px' }} />
                Request Sent
            </RequestStatusRequested>,
        'Expired':
            <RequestStatusFailed>
                <ExclamationCircleOutlined style={{ marginRight: '5px' }} />
                Request Expired
            </RequestStatusFailed>
    };

    // Temporarily allow old statuses to work with new status setup
    requestStatuses['complete'] = requestStatuses['Completed'];
    requestStatuses['pending'] = requestStatuses['Scanning'];
    requestStatuses['open'] = requestStatuses['Uploading'];
    requestStatuses['requested'] = requestStatuses['Requested'];
    requestStatuses['Malware'] = requestStatuses['Failed'];

    // Table Columns
    const tableColumns = [
        {
            title: 'Upload',
            dataIndex: 'uploadId',
            render(value: string, record: ListUploadEntry, _index: number) {
                const conversationId = record.conversationId;
                const conversionStatus = record.conversionStatus || 'N/A';
                const files = record.files;
                // customize what button displays based on status
                if (record.status === 'complete' || record.status === 'Completed') {
                    return files && files.length && files.map((file, index) => (
                        <FileWrapper>
                            <DownloadAttachmentButton
                                conversationId={conversationId}
                                conversionStatus={conversionStatus}
                                uploadId={record.uploadId}
                                fileName={file.Name || ''}
                                fileId={file.FileId || ''}
                                multiFile={!!file.FileId}
                                // only show image conversion after v2 deployment date
                                showImageConverter={showImageConverter && +record.createdAt > Date.parse('2024-03-08T00:00:00.000Z')}
                            />
                            {index < files.length - 1 &&
                                <Divider
                                    style={{
                                        marginTop: '2px',
                                        marginBottom: '2px',
                                    }}
                                />
                            }
                        </FileWrapper>
                    ));
                }
                if (record.status === 'open' || record.status === 'Uploading') {
                    return (
                        <Button
                            type='default'
                            disabled
                            icon={<CloudUploadOutlined />}
                            style={{ backgroundColor: '#fff', color: '#1998d5', border: '1px solid #1998d5', margin: '-5px 0px' }}

                        >
                            Awaiting Upload
                        </Button>
                    )
                }
                if (record.status === 'pending' || record.status === 'Scanning') {
                    return (
                        <Button
                            type='default'
                            disabled
                            icon={<MonitorOutlined />}
                            style={{ backgroundColor: '#fff', color: '#1998d5', border: '1px solid #1998d5', margin: '-5px 0px' }}

                        >
                            Scanning File
                        </Button>
                    )
                }
                if (record.status === 'Expired') {
                    return (
                        <Button
                            type='default'
                            disabled
                            icon={<ExclamationCircleOutlined />}
                            style={{ backgroundColor: '#fff', color: 'red', border: '1px solid red', margin: '-5px 0px' }}

                        >
                            Expired
                        </Button>
                    )
                }
                if (record.status === 'requested' || record.status === 'Requested') {
                    return (
                        <Button
                            type='default'
                            onClick={() => { createUpload(caseId, conversationId, value) }}
                            icon={<ReloadOutlined />}
                            style={{ color: 'orange', border: '1px solid orange', margin: '-5px 0px' }}

                        >
                            Resend Request
                        </Button>
                    )
                }
                if (record.status === 'Malware' || record.status === 'Failed') {
                    return (
                        <Button
                            type='default'
                            disabled
                            icon={<ExclamationCircleOutlined />}
                            style={{ backgroundColor: '#fff', color: 'red', border: '1px solid red', margin: '-5px 0px' }}

                        >
                            Corrupt File
                        </Button>
                    )
                }
            }
        },
        {
            title: 'Created',
            dataIndex: 'createdAt',
            sorter: (a: ListUploadEntry, b: ListUploadEntry) => {
                // Convert the strings to numbers for proper comparison
                return Number(a.createdAt) - Number(b.createdAt);
            },
            defaultSortOrder: 'descending',
            render(value: string, record: ListUploadEntry, _index: number) {
                const createdString = new Date(+value);
                const createdDate = createdString.toString().slice(3, 21);
                return (
                    <TableRowDate>
                        {createdDate}
                    </TableRowDate>
                )
            }
        },
    ];

    //page content
    return (
        <FileUploadBody>
            {/* Button Component */}
            {((!uploadId || requestStatus === 'Completed' || requestStatus === 'complete' ||
                requestStatus === 'Expired' || requestStatus === 'Failed') &&
                conversationStatus === 'open' && (conversationId || caseId)) &&
                <Button
                    type='primary'
                    icon={<SendOutlined />}
                    loading={createLoading}
                    onClick={() => {
                        setCreateLoading(true);
                        createUpload(caseId, conversationId);
                    }}
                    style={{ margin: '0px' }}
                >
                    File Upload Request
                </Button>
            }
            {/* Request Details Component */}
            {requestStatus &&
                <>
                    <Divider
                        orientation='left'
                        style={{
                            color: '#1890ff',
                            margin: '10px 0px'
                        }}
                    >
                        <FileSearchOutlined style={{ marginRight: '5px' }} />
                        Request Details
                    </Divider>
                    {requestStatuses[requestStatus]}
                </>
            }
            {/* File Details Component*/}
            <Divider
                orientation='left'
                style={{
                    color: '#1890ff',
                    margin: '15px 0px 10px 0px'
                }}
            >
                <FileImageOutlined style={{ marginRight: '5px' }} />
                File Details
            </Divider>
            <Table
                columns={tableColumns}
                dataSource={fileDetails || undefined}
                loading={loading}
                showHeader={false}
                rowKey={record => record.uploadId}
                size='small'
                style={{
                    backgroundColor: '#fff'
                }}
                pagination={{
                    pageSize: 4,
                    position: ['bottomCenter'],
                }}
            />
            {/* Refresh List Component*/}
            <FileDetailsReminder>
                Not Seeing the File you are looking for?
            </FileDetailsReminder>
            <Button
                type='primary'
                loading={refreshLoading}
                onClick={() => {
                    setRefreshLoading(true);
                    listFileDetails(conversationId, uploadId, customerId, caseId);
                }}
                icon={<ReloadOutlined />}
            >
                Refresh
            </Button>
        </FileUploadBody>
    );
}
