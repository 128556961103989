import { HttpRequest as __HttpRequest } from "@aws-sdk/protocol-http";
import { _json, decorateServiceException as __decorateServiceException, expectBoolean as __expectBoolean, expectNonNull as __expectNonNull, expectNumber as __expectNumber, expectObject as __expectObject, expectString as __expectString, map, parseEpochTimestamp as __parseEpochTimestamp, resolvedPath as __resolvedPath, take, withBaseException, } from "@aws-sdk/smithy-client";
import { v4 as generateIdempotencyToken } from "uuid";
import { ChimeSDKMessagingServiceException as __BaseException } from "../models/ChimeSDKMessagingServiceException";
import { BadRequestException, ConflictException, ForbiddenException, NotFoundException, ResourceLimitExceededException, ServiceFailureException, ServiceUnavailableException, ThrottledClientException, UnauthorizedClientException, } from "../models/models_0";
export const se_AssociateChannelFlowCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = map({}, isSerializableHeaderValue, {
        "content-type": "application/json",
        "x-amz-chime-bearer": input.ChimeBearer,
    });
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/channels/{ChannelArn}/channel-flow";
    resolvedPath = __resolvedPath(resolvedPath, input, "ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    let body;
    body = JSON.stringify(take(input, {
        ChannelFlowArn: [],
    }));
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "PUT",
        headers,
        path: resolvedPath,
        body,
    });
};
export const se_BatchCreateChannelMembershipCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = map({}, isSerializableHeaderValue, {
        "content-type": "application/json",
        "x-amz-chime-bearer": input.ChimeBearer,
    });
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/channels/{ChannelArn}/memberships";
    resolvedPath = __resolvedPath(resolvedPath, input, "ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    const query = map({
        operation: [, "batch-create"],
    });
    let body;
    body = JSON.stringify(take(input, {
        MemberArns: (_) => _json(_),
        SubChannelId: [],
        Type: [],
    }));
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "POST",
        headers,
        path: resolvedPath,
        query,
        body,
    });
};
export const se_ChannelFlowCallbackCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = {
        "content-type": "application/json",
    };
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/channels/{ChannelArn}";
    resolvedPath = __resolvedPath(resolvedPath, input, "ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    const query = map({
        operation: [, "channel-flow-callback"],
    });
    let body;
    body = JSON.stringify(take(input, {
        CallbackId: [true, (_) => _ ?? generateIdempotencyToken()],
        ChannelMessage: (_) => _json(_),
        DeleteResource: [],
    }));
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "POST",
        headers,
        path: resolvedPath,
        query,
        body,
    });
};
export const se_CreateChannelCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = map({}, isSerializableHeaderValue, {
        "content-type": "application/json",
        "x-amz-chime-bearer": input.ChimeBearer,
    });
    const resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/channels";
    let body;
    body = JSON.stringify(take(input, {
        AppInstanceArn: [],
        ChannelId: [],
        ClientRequestToken: [true, (_) => _ ?? generateIdempotencyToken()],
        ElasticChannelConfiguration: (_) => _json(_),
        ExpirationSettings: (_) => _json(_),
        MemberArns: (_) => _json(_),
        Metadata: [],
        Mode: [],
        ModeratorArns: (_) => _json(_),
        Name: [],
        Privacy: [],
        Tags: (_) => _json(_),
    }));
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "POST",
        headers,
        path: resolvedPath,
        body,
    });
};
export const se_CreateChannelBanCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = map({}, isSerializableHeaderValue, {
        "content-type": "application/json",
        "x-amz-chime-bearer": input.ChimeBearer,
    });
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/channels/{ChannelArn}/bans";
    resolvedPath = __resolvedPath(resolvedPath, input, "ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    let body;
    body = JSON.stringify(take(input, {
        MemberArn: [],
    }));
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "POST",
        headers,
        path: resolvedPath,
        body,
    });
};
export const se_CreateChannelFlowCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = {
        "content-type": "application/json",
    };
    const resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/channel-flows";
    let body;
    body = JSON.stringify(take(input, {
        AppInstanceArn: [],
        ClientRequestToken: [true, (_) => _ ?? generateIdempotencyToken()],
        Name: [],
        Processors: (_) => _json(_),
        Tags: (_) => _json(_),
    }));
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "POST",
        headers,
        path: resolvedPath,
        body,
    });
};
export const se_CreateChannelMembershipCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = map({}, isSerializableHeaderValue, {
        "content-type": "application/json",
        "x-amz-chime-bearer": input.ChimeBearer,
    });
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/channels/{ChannelArn}/memberships";
    resolvedPath = __resolvedPath(resolvedPath, input, "ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    let body;
    body = JSON.stringify(take(input, {
        MemberArn: [],
        SubChannelId: [],
        Type: [],
    }));
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "POST",
        headers,
        path: resolvedPath,
        body,
    });
};
export const se_CreateChannelModeratorCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = map({}, isSerializableHeaderValue, {
        "content-type": "application/json",
        "x-amz-chime-bearer": input.ChimeBearer,
    });
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/channels/{ChannelArn}/moderators";
    resolvedPath = __resolvedPath(resolvedPath, input, "ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    let body;
    body = JSON.stringify(take(input, {
        ChannelModeratorArn: [],
    }));
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "POST",
        headers,
        path: resolvedPath,
        body,
    });
};
export const se_DeleteChannelCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = map({}, isSerializableHeaderValue, {
        "x-amz-chime-bearer": input.ChimeBearer,
    });
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/channels/{ChannelArn}";
    resolvedPath = __resolvedPath(resolvedPath, input, "ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    let body;
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "DELETE",
        headers,
        path: resolvedPath,
        body,
    });
};
export const se_DeleteChannelBanCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = map({}, isSerializableHeaderValue, {
        "x-amz-chime-bearer": input.ChimeBearer,
    });
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/channels/{ChannelArn}/bans/{MemberArn}";
    resolvedPath = __resolvedPath(resolvedPath, input, "ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    resolvedPath = __resolvedPath(resolvedPath, input, "MemberArn", () => input.MemberArn, "{MemberArn}", false);
    let body;
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "DELETE",
        headers,
        path: resolvedPath,
        body,
    });
};
export const se_DeleteChannelFlowCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = {};
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/channel-flows/{ChannelFlowArn}";
    resolvedPath = __resolvedPath(resolvedPath, input, "ChannelFlowArn", () => input.ChannelFlowArn, "{ChannelFlowArn}", false);
    let body;
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "DELETE",
        headers,
        path: resolvedPath,
        body,
    });
};
export const se_DeleteChannelMembershipCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = map({}, isSerializableHeaderValue, {
        "x-amz-chime-bearer": input.ChimeBearer,
    });
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` +
        "/channels/{ChannelArn}/memberships/{MemberArn}";
    resolvedPath = __resolvedPath(resolvedPath, input, "ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    resolvedPath = __resolvedPath(resolvedPath, input, "MemberArn", () => input.MemberArn, "{MemberArn}", false);
    const query = map({
        "sub-channel-id": [, input.SubChannelId],
    });
    let body;
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "DELETE",
        headers,
        path: resolvedPath,
        query,
        body,
    });
};
export const se_DeleteChannelMessageCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = map({}, isSerializableHeaderValue, {
        "x-amz-chime-bearer": input.ChimeBearer,
    });
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` +
        "/channels/{ChannelArn}/messages/{MessageId}";
    resolvedPath = __resolvedPath(resolvedPath, input, "ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    resolvedPath = __resolvedPath(resolvedPath, input, "MessageId", () => input.MessageId, "{MessageId}", false);
    const query = map({
        "sub-channel-id": [, input.SubChannelId],
    });
    let body;
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "DELETE",
        headers,
        path: resolvedPath,
        query,
        body,
    });
};
export const se_DeleteChannelModeratorCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = map({}, isSerializableHeaderValue, {
        "x-amz-chime-bearer": input.ChimeBearer,
    });
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` +
        "/channels/{ChannelArn}/moderators/{ChannelModeratorArn}";
    resolvedPath = __resolvedPath(resolvedPath, input, "ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    resolvedPath = __resolvedPath(resolvedPath, input, "ChannelModeratorArn", () => input.ChannelModeratorArn, "{ChannelModeratorArn}", false);
    let body;
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "DELETE",
        headers,
        path: resolvedPath,
        body,
    });
};
export const se_DeleteMessagingStreamingConfigurationsCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = {};
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` +
        "/app-instances/{AppInstanceArn}/streaming-configurations";
    resolvedPath = __resolvedPath(resolvedPath, input, "AppInstanceArn", () => input.AppInstanceArn, "{AppInstanceArn}", false);
    let body;
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "DELETE",
        headers,
        path: resolvedPath,
        body,
    });
};
export const se_DescribeChannelCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = map({}, isSerializableHeaderValue, {
        "x-amz-chime-bearer": input.ChimeBearer,
    });
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/channels/{ChannelArn}";
    resolvedPath = __resolvedPath(resolvedPath, input, "ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    let body;
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "GET",
        headers,
        path: resolvedPath,
        body,
    });
};
export const se_DescribeChannelBanCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = map({}, isSerializableHeaderValue, {
        "x-amz-chime-bearer": input.ChimeBearer,
    });
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/channels/{ChannelArn}/bans/{MemberArn}";
    resolvedPath = __resolvedPath(resolvedPath, input, "ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    resolvedPath = __resolvedPath(resolvedPath, input, "MemberArn", () => input.MemberArn, "{MemberArn}", false);
    let body;
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "GET",
        headers,
        path: resolvedPath,
        body,
    });
};
export const se_DescribeChannelFlowCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = {};
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/channel-flows/{ChannelFlowArn}";
    resolvedPath = __resolvedPath(resolvedPath, input, "ChannelFlowArn", () => input.ChannelFlowArn, "{ChannelFlowArn}", false);
    let body;
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "GET",
        headers,
        path: resolvedPath,
        body,
    });
};
export const se_DescribeChannelMembershipCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = map({}, isSerializableHeaderValue, {
        "x-amz-chime-bearer": input.ChimeBearer,
    });
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` +
        "/channels/{ChannelArn}/memberships/{MemberArn}";
    resolvedPath = __resolvedPath(resolvedPath, input, "ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    resolvedPath = __resolvedPath(resolvedPath, input, "MemberArn", () => input.MemberArn, "{MemberArn}", false);
    const query = map({
        "sub-channel-id": [, input.SubChannelId],
    });
    let body;
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "GET",
        headers,
        path: resolvedPath,
        query,
        body,
    });
};
export const se_DescribeChannelMembershipForAppInstanceUserCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = map({}, isSerializableHeaderValue, {
        "x-amz-chime-bearer": input.ChimeBearer,
    });
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/channels/{ChannelArn}";
    resolvedPath = __resolvedPath(resolvedPath, input, "ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    const query = map({
        scope: [, "app-instance-user-membership"],
        "app-instance-user-arn": [, __expectNonNull(input.AppInstanceUserArn, `AppInstanceUserArn`)],
    });
    let body;
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "GET",
        headers,
        path: resolvedPath,
        query,
        body,
    });
};
export const se_DescribeChannelModeratedByAppInstanceUserCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = map({}, isSerializableHeaderValue, {
        "x-amz-chime-bearer": input.ChimeBearer,
    });
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/channels/{ChannelArn}";
    resolvedPath = __resolvedPath(resolvedPath, input, "ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    const query = map({
        scope: [, "app-instance-user-moderated-channel"],
        "app-instance-user-arn": [, __expectNonNull(input.AppInstanceUserArn, `AppInstanceUserArn`)],
    });
    let body;
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "GET",
        headers,
        path: resolvedPath,
        query,
        body,
    });
};
export const se_DescribeChannelModeratorCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = map({}, isSerializableHeaderValue, {
        "x-amz-chime-bearer": input.ChimeBearer,
    });
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` +
        "/channels/{ChannelArn}/moderators/{ChannelModeratorArn}";
    resolvedPath = __resolvedPath(resolvedPath, input, "ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    resolvedPath = __resolvedPath(resolvedPath, input, "ChannelModeratorArn", () => input.ChannelModeratorArn, "{ChannelModeratorArn}", false);
    let body;
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "GET",
        headers,
        path: resolvedPath,
        body,
    });
};
export const se_DisassociateChannelFlowCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = map({}, isSerializableHeaderValue, {
        "x-amz-chime-bearer": input.ChimeBearer,
    });
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` +
        "/channels/{ChannelArn}/channel-flow/{ChannelFlowArn}";
    resolvedPath = __resolvedPath(resolvedPath, input, "ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    resolvedPath = __resolvedPath(resolvedPath, input, "ChannelFlowArn", () => input.ChannelFlowArn, "{ChannelFlowArn}", false);
    let body;
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "DELETE",
        headers,
        path: resolvedPath,
        body,
    });
};
export const se_GetChannelMembershipPreferencesCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = map({}, isSerializableHeaderValue, {
        "x-amz-chime-bearer": input.ChimeBearer,
    });
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` +
        "/channels/{ChannelArn}/memberships/{MemberArn}/preferences";
    resolvedPath = __resolvedPath(resolvedPath, input, "ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    resolvedPath = __resolvedPath(resolvedPath, input, "MemberArn", () => input.MemberArn, "{MemberArn}", false);
    let body;
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "GET",
        headers,
        path: resolvedPath,
        body,
    });
};
export const se_GetChannelMessageCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = map({}, isSerializableHeaderValue, {
        "x-amz-chime-bearer": input.ChimeBearer,
    });
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` +
        "/channels/{ChannelArn}/messages/{MessageId}";
    resolvedPath = __resolvedPath(resolvedPath, input, "ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    resolvedPath = __resolvedPath(resolvedPath, input, "MessageId", () => input.MessageId, "{MessageId}", false);
    const query = map({
        "sub-channel-id": [, input.SubChannelId],
    });
    let body;
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "GET",
        headers,
        path: resolvedPath,
        query,
        body,
    });
};
export const se_GetChannelMessageStatusCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = map({}, isSerializableHeaderValue, {
        "x-amz-chime-bearer": input.ChimeBearer,
    });
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` +
        "/channels/{ChannelArn}/messages/{MessageId}";
    resolvedPath = __resolvedPath(resolvedPath, input, "ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    resolvedPath = __resolvedPath(resolvedPath, input, "MessageId", () => input.MessageId, "{MessageId}", false);
    const query = map({
        scope: [, "message-status"],
        "sub-channel-id": [, input.SubChannelId],
    });
    let body;
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "GET",
        headers,
        path: resolvedPath,
        query,
        body,
    });
};
export const se_GetMessagingSessionEndpointCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = {
        "content-type": "application/json",
    };
    const resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/endpoints/messaging-session";
    let body;
    body = "";
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "GET",
        headers,
        path: resolvedPath,
        body,
    });
};
export const se_GetMessagingStreamingConfigurationsCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = {};
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` +
        "/app-instances/{AppInstanceArn}/streaming-configurations";
    resolvedPath = __resolvedPath(resolvedPath, input, "AppInstanceArn", () => input.AppInstanceArn, "{AppInstanceArn}", false);
    let body;
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "GET",
        headers,
        path: resolvedPath,
        body,
    });
};
export const se_ListChannelBansCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = map({}, isSerializableHeaderValue, {
        "x-amz-chime-bearer": input.ChimeBearer,
    });
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/channels/{ChannelArn}/bans";
    resolvedPath = __resolvedPath(resolvedPath, input, "ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    const query = map({
        "max-results": [() => input.MaxResults !== void 0, () => input.MaxResults.toString()],
        "next-token": [, input.NextToken],
    });
    let body;
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "GET",
        headers,
        path: resolvedPath,
        query,
        body,
    });
};
export const se_ListChannelFlowsCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = {};
    const resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/channel-flows";
    const query = map({
        "app-instance-arn": [, __expectNonNull(input.AppInstanceArn, `AppInstanceArn`)],
        "max-results": [() => input.MaxResults !== void 0, () => input.MaxResults.toString()],
        "next-token": [, input.NextToken],
    });
    let body;
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "GET",
        headers,
        path: resolvedPath,
        query,
        body,
    });
};
export const se_ListChannelMembershipsCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = map({}, isSerializableHeaderValue, {
        "x-amz-chime-bearer": input.ChimeBearer,
    });
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/channels/{ChannelArn}/memberships";
    resolvedPath = __resolvedPath(resolvedPath, input, "ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    const query = map({
        type: [, input.Type],
        "max-results": [() => input.MaxResults !== void 0, () => input.MaxResults.toString()],
        "next-token": [, input.NextToken],
        "sub-channel-id": [, input.SubChannelId],
    });
    let body;
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "GET",
        headers,
        path: resolvedPath,
        query,
        body,
    });
};
export const se_ListChannelMembershipsForAppInstanceUserCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = map({}, isSerializableHeaderValue, {
        "x-amz-chime-bearer": input.ChimeBearer,
    });
    const resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/channels";
    const query = map({
        scope: [, "app-instance-user-memberships"],
        "app-instance-user-arn": [, input.AppInstanceUserArn],
        "max-results": [() => input.MaxResults !== void 0, () => input.MaxResults.toString()],
        "next-token": [, input.NextToken],
    });
    let body;
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "GET",
        headers,
        path: resolvedPath,
        query,
        body,
    });
};
export const se_ListChannelMessagesCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = map({}, isSerializableHeaderValue, {
        "x-amz-chime-bearer": input.ChimeBearer,
    });
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/channels/{ChannelArn}/messages";
    resolvedPath = __resolvedPath(resolvedPath, input, "ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    const query = map({
        "sort-order": [, input.SortOrder],
        "not-before": [
            () => input.NotBefore !== void 0,
            () => (input.NotBefore.toISOString().split(".")[0] + "Z").toString(),
        ],
        "not-after": [
            () => input.NotAfter !== void 0,
            () => (input.NotAfter.toISOString().split(".")[0] + "Z").toString(),
        ],
        "max-results": [() => input.MaxResults !== void 0, () => input.MaxResults.toString()],
        "next-token": [, input.NextToken],
        "sub-channel-id": [, input.SubChannelId],
    });
    let body;
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "GET",
        headers,
        path: resolvedPath,
        query,
        body,
    });
};
export const se_ListChannelModeratorsCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = map({}, isSerializableHeaderValue, {
        "x-amz-chime-bearer": input.ChimeBearer,
    });
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/channels/{ChannelArn}/moderators";
    resolvedPath = __resolvedPath(resolvedPath, input, "ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    const query = map({
        "max-results": [() => input.MaxResults !== void 0, () => input.MaxResults.toString()],
        "next-token": [, input.NextToken],
    });
    let body;
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "GET",
        headers,
        path: resolvedPath,
        query,
        body,
    });
};
export const se_ListChannelsCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = map({}, isSerializableHeaderValue, {
        "x-amz-chime-bearer": input.ChimeBearer,
    });
    const resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/channels";
    const query = map({
        "app-instance-arn": [, __expectNonNull(input.AppInstanceArn, `AppInstanceArn`)],
        privacy: [, input.Privacy],
        "max-results": [() => input.MaxResults !== void 0, () => input.MaxResults.toString()],
        "next-token": [, input.NextToken],
    });
    let body;
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "GET",
        headers,
        path: resolvedPath,
        query,
        body,
    });
};
export const se_ListChannelsAssociatedWithChannelFlowCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = {};
    const resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/channels";
    const query = map({
        scope: [, "channel-flow-associations"],
        "channel-flow-arn": [, __expectNonNull(input.ChannelFlowArn, `ChannelFlowArn`)],
        "max-results": [() => input.MaxResults !== void 0, () => input.MaxResults.toString()],
        "next-token": [, input.NextToken],
    });
    let body;
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "GET",
        headers,
        path: resolvedPath,
        query,
        body,
    });
};
export const se_ListChannelsModeratedByAppInstanceUserCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = map({}, isSerializableHeaderValue, {
        "x-amz-chime-bearer": input.ChimeBearer,
    });
    const resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/channels";
    const query = map({
        scope: [, "app-instance-user-moderated-channels"],
        "app-instance-user-arn": [, input.AppInstanceUserArn],
        "max-results": [() => input.MaxResults !== void 0, () => input.MaxResults.toString()],
        "next-token": [, input.NextToken],
    });
    let body;
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "GET",
        headers,
        path: resolvedPath,
        query,
        body,
    });
};
export const se_ListSubChannelsCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = map({}, isSerializableHeaderValue, {
        "x-amz-chime-bearer": input.ChimeBearer,
    });
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/channels/{ChannelArn}/subchannels";
    resolvedPath = __resolvedPath(resolvedPath, input, "ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    const query = map({
        "max-results": [() => input.MaxResults !== void 0, () => input.MaxResults.toString()],
        "next-token": [, input.NextToken],
    });
    let body;
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "GET",
        headers,
        path: resolvedPath,
        query,
        body,
    });
};
export const se_ListTagsForResourceCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = {};
    const resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/tags";
    const query = map({
        arn: [, __expectNonNull(input.ResourceARN, `ResourceARN`)],
    });
    let body;
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "GET",
        headers,
        path: resolvedPath,
        query,
        body,
    });
};
export const se_PutChannelExpirationSettingsCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = map({}, isSerializableHeaderValue, {
        "content-type": "application/json",
        "x-amz-chime-bearer": input.ChimeBearer,
    });
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` +
        "/channels/{ChannelArn}/expiration-settings";
    resolvedPath = __resolvedPath(resolvedPath, input, "ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    let body;
    body = JSON.stringify(take(input, {
        ExpirationSettings: (_) => _json(_),
    }));
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "PUT",
        headers,
        path: resolvedPath,
        body,
    });
};
export const se_PutChannelMembershipPreferencesCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = map({}, isSerializableHeaderValue, {
        "content-type": "application/json",
        "x-amz-chime-bearer": input.ChimeBearer,
    });
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` +
        "/channels/{ChannelArn}/memberships/{MemberArn}/preferences";
    resolvedPath = __resolvedPath(resolvedPath, input, "ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    resolvedPath = __resolvedPath(resolvedPath, input, "MemberArn", () => input.MemberArn, "{MemberArn}", false);
    let body;
    body = JSON.stringify(take(input, {
        Preferences: (_) => _json(_),
    }));
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "PUT",
        headers,
        path: resolvedPath,
        body,
    });
};
export const se_PutMessagingStreamingConfigurationsCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = {
        "content-type": "application/json",
    };
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` +
        "/app-instances/{AppInstanceArn}/streaming-configurations";
    resolvedPath = __resolvedPath(resolvedPath, input, "AppInstanceArn", () => input.AppInstanceArn, "{AppInstanceArn}", false);
    let body;
    body = JSON.stringify(take(input, {
        StreamingConfigurations: (_) => _json(_),
    }));
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "PUT",
        headers,
        path: resolvedPath,
        body,
    });
};
export const se_RedactChannelMessageCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = map({}, isSerializableHeaderValue, {
        "content-type": "application/json",
        "x-amz-chime-bearer": input.ChimeBearer,
    });
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` +
        "/channels/{ChannelArn}/messages/{MessageId}";
    resolvedPath = __resolvedPath(resolvedPath, input, "ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    resolvedPath = __resolvedPath(resolvedPath, input, "MessageId", () => input.MessageId, "{MessageId}", false);
    const query = map({
        operation: [, "redact"],
    });
    let body;
    body = JSON.stringify(take(input, {
        SubChannelId: [],
    }));
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "POST",
        headers,
        path: resolvedPath,
        query,
        body,
    });
};
export const se_SearchChannelsCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = map({}, isSerializableHeaderValue, {
        "content-type": "application/json",
        "x-amz-chime-bearer": input.ChimeBearer,
    });
    const resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/channels";
    const query = map({
        operation: [, "search"],
        "max-results": [() => input.MaxResults !== void 0, () => input.MaxResults.toString()],
        "next-token": [, input.NextToken],
    });
    let body;
    body = JSON.stringify(take(input, {
        Fields: (_) => _json(_),
    }));
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "POST",
        headers,
        path: resolvedPath,
        query,
        body,
    });
};
export const se_SendChannelMessageCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = map({}, isSerializableHeaderValue, {
        "content-type": "application/json",
        "x-amz-chime-bearer": input.ChimeBearer,
    });
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/channels/{ChannelArn}/messages";
    resolvedPath = __resolvedPath(resolvedPath, input, "ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    let body;
    body = JSON.stringify(take(input, {
        ClientRequestToken: [true, (_) => _ ?? generateIdempotencyToken()],
        Content: [],
        ContentType: [],
        MessageAttributes: (_) => _json(_),
        Metadata: [],
        Persistence: [],
        PushNotification: (_) => _json(_),
        SubChannelId: [],
        Type: [],
    }));
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "POST",
        headers,
        path: resolvedPath,
        body,
    });
};
export const se_TagResourceCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = {
        "content-type": "application/json",
    };
    const resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/tags";
    const query = map({
        operation: [, "tag-resource"],
    });
    let body;
    body = JSON.stringify(take(input, {
        ResourceARN: [],
        Tags: (_) => _json(_),
    }));
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "POST",
        headers,
        path: resolvedPath,
        query,
        body,
    });
};
export const se_UntagResourceCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = {
        "content-type": "application/json",
    };
    const resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/tags";
    const query = map({
        operation: [, "untag-resource"],
    });
    let body;
    body = JSON.stringify(take(input, {
        ResourceARN: [],
        TagKeys: (_) => _json(_),
    }));
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "POST",
        headers,
        path: resolvedPath,
        query,
        body,
    });
};
export const se_UpdateChannelCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = map({}, isSerializableHeaderValue, {
        "content-type": "application/json",
        "x-amz-chime-bearer": input.ChimeBearer,
    });
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/channels/{ChannelArn}";
    resolvedPath = __resolvedPath(resolvedPath, input, "ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    let body;
    body = JSON.stringify(take(input, {
        Metadata: [],
        Mode: [],
        Name: [],
    }));
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "PUT",
        headers,
        path: resolvedPath,
        body,
    });
};
export const se_UpdateChannelFlowCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = {
        "content-type": "application/json",
    };
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/channel-flows/{ChannelFlowArn}";
    resolvedPath = __resolvedPath(resolvedPath, input, "ChannelFlowArn", () => input.ChannelFlowArn, "{ChannelFlowArn}", false);
    let body;
    body = JSON.stringify(take(input, {
        Name: [],
        Processors: (_) => _json(_),
    }));
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "PUT",
        headers,
        path: resolvedPath,
        body,
    });
};
export const se_UpdateChannelMessageCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = map({}, isSerializableHeaderValue, {
        "content-type": "application/json",
        "x-amz-chime-bearer": input.ChimeBearer,
    });
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` +
        "/channels/{ChannelArn}/messages/{MessageId}";
    resolvedPath = __resolvedPath(resolvedPath, input, "ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    resolvedPath = __resolvedPath(resolvedPath, input, "MessageId", () => input.MessageId, "{MessageId}", false);
    let body;
    body = JSON.stringify(take(input, {
        Content: [],
        ContentType: [],
        Metadata: [],
        SubChannelId: [],
    }));
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "PUT",
        headers,
        path: resolvedPath,
        body,
    });
};
export const se_UpdateChannelReadMarkerCommand = async (input, context) => {
    const { hostname, protocol = "https", port, path: basePath } = await context.endpoint();
    const headers = map({}, isSerializableHeaderValue, {
        "x-amz-chime-bearer": input.ChimeBearer,
    });
    let resolvedPath = `${basePath?.endsWith("/") ? basePath.slice(0, -1) : basePath || ""}` + "/channels/{ChannelArn}/readMarker";
    resolvedPath = __resolvedPath(resolvedPath, input, "ChannelArn", () => input.ChannelArn, "{ChannelArn}", false);
    let body;
    return new __HttpRequest({
        protocol,
        hostname,
        port,
        method: "PUT",
        headers,
        path: resolvedPath,
        body,
    });
};
export const de_AssociateChannelFlowCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_AssociateChannelFlowCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    await collectBody(output.body, context);
    return contents;
};
const de_AssociateChannelFlowCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ConflictException":
        case "com.amazonaws.chimesdkmessaging#ConflictException":
            throw await de_ConflictExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "NotFoundException":
        case "com.amazonaws.chimesdkmessaging#NotFoundException":
            throw await de_NotFoundExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_BatchCreateChannelMembershipCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_BatchCreateChannelMembershipCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        BatchChannelMemberships: _json,
        Errors: _json,
    });
    Object.assign(contents, doc);
    return contents;
};
const de_BatchCreateChannelMembershipCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "NotFoundException":
        case "com.amazonaws.chimesdkmessaging#NotFoundException":
            throw await de_NotFoundExceptionRes(parsedOutput, context);
        case "ResourceLimitExceededException":
        case "com.amazonaws.chimesdkmessaging#ResourceLimitExceededException":
            throw await de_ResourceLimitExceededExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_ChannelFlowCallbackCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_ChannelFlowCallbackCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        CallbackId: __expectString,
        ChannelArn: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
const de_ChannelFlowCallbackCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ConflictException":
        case "com.amazonaws.chimesdkmessaging#ConflictException":
            throw await de_ConflictExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_CreateChannelCommand = async (output, context) => {
    if (output.statusCode !== 201 && output.statusCode >= 300) {
        return de_CreateChannelCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelArn: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
const de_CreateChannelCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ConflictException":
        case "com.amazonaws.chimesdkmessaging#ConflictException":
            throw await de_ConflictExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "ResourceLimitExceededException":
        case "com.amazonaws.chimesdkmessaging#ResourceLimitExceededException":
            throw await de_ResourceLimitExceededExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_CreateChannelBanCommand = async (output, context) => {
    if (output.statusCode !== 201 && output.statusCode >= 300) {
        return de_CreateChannelBanCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelArn: __expectString,
        Member: _json,
    });
    Object.assign(contents, doc);
    return contents;
};
const de_CreateChannelBanCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ConflictException":
        case "com.amazonaws.chimesdkmessaging#ConflictException":
            throw await de_ConflictExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "ResourceLimitExceededException":
        case "com.amazonaws.chimesdkmessaging#ResourceLimitExceededException":
            throw await de_ResourceLimitExceededExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_CreateChannelFlowCommand = async (output, context) => {
    if (output.statusCode !== 201 && output.statusCode >= 300) {
        return de_CreateChannelFlowCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelFlowArn: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
const de_CreateChannelFlowCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ConflictException":
        case "com.amazonaws.chimesdkmessaging#ConflictException":
            throw await de_ConflictExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "ResourceLimitExceededException":
        case "com.amazonaws.chimesdkmessaging#ResourceLimitExceededException":
            throw await de_ResourceLimitExceededExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_CreateChannelMembershipCommand = async (output, context) => {
    if (output.statusCode !== 201 && output.statusCode >= 300) {
        return de_CreateChannelMembershipCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelArn: __expectString,
        Member: _json,
        SubChannelId: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
const de_CreateChannelMembershipCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ConflictException":
        case "com.amazonaws.chimesdkmessaging#ConflictException":
            throw await de_ConflictExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "NotFoundException":
        case "com.amazonaws.chimesdkmessaging#NotFoundException":
            throw await de_NotFoundExceptionRes(parsedOutput, context);
        case "ResourceLimitExceededException":
        case "com.amazonaws.chimesdkmessaging#ResourceLimitExceededException":
            throw await de_ResourceLimitExceededExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_CreateChannelModeratorCommand = async (output, context) => {
    if (output.statusCode !== 201 && output.statusCode >= 300) {
        return de_CreateChannelModeratorCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelArn: __expectString,
        ChannelModerator: _json,
    });
    Object.assign(contents, doc);
    return contents;
};
const de_CreateChannelModeratorCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ConflictException":
        case "com.amazonaws.chimesdkmessaging#ConflictException":
            throw await de_ConflictExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "ResourceLimitExceededException":
        case "com.amazonaws.chimesdkmessaging#ResourceLimitExceededException":
            throw await de_ResourceLimitExceededExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_DeleteChannelCommand = async (output, context) => {
    if (output.statusCode !== 204 && output.statusCode >= 300) {
        return de_DeleteChannelCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    await collectBody(output.body, context);
    return contents;
};
const de_DeleteChannelCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ConflictException":
        case "com.amazonaws.chimesdkmessaging#ConflictException":
            throw await de_ConflictExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_DeleteChannelBanCommand = async (output, context) => {
    if (output.statusCode !== 204 && output.statusCode >= 300) {
        return de_DeleteChannelBanCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    await collectBody(output.body, context);
    return contents;
};
const de_DeleteChannelBanCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_DeleteChannelFlowCommand = async (output, context) => {
    if (output.statusCode !== 204 && output.statusCode >= 300) {
        return de_DeleteChannelFlowCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    await collectBody(output.body, context);
    return contents;
};
const de_DeleteChannelFlowCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ConflictException":
        case "com.amazonaws.chimesdkmessaging#ConflictException":
            throw await de_ConflictExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_DeleteChannelMembershipCommand = async (output, context) => {
    if (output.statusCode !== 204 && output.statusCode >= 300) {
        return de_DeleteChannelMembershipCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    await collectBody(output.body, context);
    return contents;
};
const de_DeleteChannelMembershipCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ConflictException":
        case "com.amazonaws.chimesdkmessaging#ConflictException":
            throw await de_ConflictExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_DeleteChannelMessageCommand = async (output, context) => {
    if (output.statusCode !== 204 && output.statusCode >= 300) {
        return de_DeleteChannelMessageCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    await collectBody(output.body, context);
    return contents;
};
const de_DeleteChannelMessageCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_DeleteChannelModeratorCommand = async (output, context) => {
    if (output.statusCode !== 204 && output.statusCode >= 300) {
        return de_DeleteChannelModeratorCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    await collectBody(output.body, context);
    return contents;
};
const de_DeleteChannelModeratorCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_DeleteMessagingStreamingConfigurationsCommand = async (output, context) => {
    if (output.statusCode !== 204 && output.statusCode >= 300) {
        return de_DeleteMessagingStreamingConfigurationsCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    await collectBody(output.body, context);
    return contents;
};
const de_DeleteMessagingStreamingConfigurationsCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_DescribeChannelCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_DescribeChannelCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        Channel: (_) => de_Channel(_, context),
    });
    Object.assign(contents, doc);
    return contents;
};
const de_DescribeChannelCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_DescribeChannelBanCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_DescribeChannelBanCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelBan: (_) => de_ChannelBan(_, context),
    });
    Object.assign(contents, doc);
    return contents;
};
const de_DescribeChannelBanCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "NotFoundException":
        case "com.amazonaws.chimesdkmessaging#NotFoundException":
            throw await de_NotFoundExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_DescribeChannelFlowCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_DescribeChannelFlowCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelFlow: (_) => de_ChannelFlow(_, context),
    });
    Object.assign(contents, doc);
    return contents;
};
const de_DescribeChannelFlowCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_DescribeChannelMembershipCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_DescribeChannelMembershipCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelMembership: (_) => de_ChannelMembership(_, context),
    });
    Object.assign(contents, doc);
    return contents;
};
const de_DescribeChannelMembershipCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "NotFoundException":
        case "com.amazonaws.chimesdkmessaging#NotFoundException":
            throw await de_NotFoundExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_DescribeChannelMembershipForAppInstanceUserCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_DescribeChannelMembershipForAppInstanceUserCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelMembership: (_) => de_ChannelMembershipForAppInstanceUserSummary(_, context),
    });
    Object.assign(contents, doc);
    return contents;
};
const de_DescribeChannelMembershipForAppInstanceUserCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_DescribeChannelModeratedByAppInstanceUserCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_DescribeChannelModeratedByAppInstanceUserCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        Channel: (_) => de_ChannelModeratedByAppInstanceUserSummary(_, context),
    });
    Object.assign(contents, doc);
    return contents;
};
const de_DescribeChannelModeratedByAppInstanceUserCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_DescribeChannelModeratorCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_DescribeChannelModeratorCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelModerator: (_) => de_ChannelModerator(_, context),
    });
    Object.assign(contents, doc);
    return contents;
};
const de_DescribeChannelModeratorCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "NotFoundException":
        case "com.amazonaws.chimesdkmessaging#NotFoundException":
            throw await de_NotFoundExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_DisassociateChannelFlowCommand = async (output, context) => {
    if (output.statusCode !== 204 && output.statusCode >= 300) {
        return de_DisassociateChannelFlowCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    await collectBody(output.body, context);
    return contents;
};
const de_DisassociateChannelFlowCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ConflictException":
        case "com.amazonaws.chimesdkmessaging#ConflictException":
            throw await de_ConflictExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "NotFoundException":
        case "com.amazonaws.chimesdkmessaging#NotFoundException":
            throw await de_NotFoundExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_GetChannelMembershipPreferencesCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_GetChannelMembershipPreferencesCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelArn: __expectString,
        Member: _json,
        Preferences: _json,
    });
    Object.assign(contents, doc);
    return contents;
};
const de_GetChannelMembershipPreferencesCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_GetChannelMessageCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_GetChannelMessageCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelMessage: (_) => de_ChannelMessage(_, context),
    });
    Object.assign(contents, doc);
    return contents;
};
const de_GetChannelMessageCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "NotFoundException":
        case "com.amazonaws.chimesdkmessaging#NotFoundException":
            throw await de_NotFoundExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_GetChannelMessageStatusCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_GetChannelMessageStatusCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        Status: _json,
    });
    Object.assign(contents, doc);
    return contents;
};
const de_GetChannelMessageStatusCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_GetMessagingSessionEndpointCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_GetMessagingSessionEndpointCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        Endpoint: _json,
    });
    Object.assign(contents, doc);
    return contents;
};
const de_GetMessagingSessionEndpointCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_GetMessagingStreamingConfigurationsCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_GetMessagingStreamingConfigurationsCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        StreamingConfigurations: _json,
    });
    Object.assign(contents, doc);
    return contents;
};
const de_GetMessagingStreamingConfigurationsCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "NotFoundException":
        case "com.amazonaws.chimesdkmessaging#NotFoundException":
            throw await de_NotFoundExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_ListChannelBansCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_ListChannelBansCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelArn: __expectString,
        ChannelBans: _json,
        NextToken: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
const de_ListChannelBansCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_ListChannelFlowsCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_ListChannelFlowsCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelFlows: _json,
        NextToken: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
const de_ListChannelFlowsCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_ListChannelMembershipsCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_ListChannelMembershipsCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelArn: __expectString,
        ChannelMemberships: _json,
        NextToken: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
const de_ListChannelMembershipsCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_ListChannelMembershipsForAppInstanceUserCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_ListChannelMembershipsForAppInstanceUserCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelMemberships: (_) => de_ChannelMembershipForAppInstanceUserSummaryList(_, context),
        NextToken: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
const de_ListChannelMembershipsForAppInstanceUserCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_ListChannelMessagesCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_ListChannelMessagesCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelArn: __expectString,
        ChannelMessages: (_) => de_ChannelMessageSummaryList(_, context),
        NextToken: __expectString,
        SubChannelId: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
const de_ListChannelMessagesCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_ListChannelModeratorsCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_ListChannelModeratorsCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelArn: __expectString,
        ChannelModerators: _json,
        NextToken: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
const de_ListChannelModeratorsCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_ListChannelsCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_ListChannelsCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        Channels: (_) => de_ChannelSummaryList(_, context),
        NextToken: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
const de_ListChannelsCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_ListChannelsAssociatedWithChannelFlowCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_ListChannelsAssociatedWithChannelFlowCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        Channels: _json,
        NextToken: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
const de_ListChannelsAssociatedWithChannelFlowCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_ListChannelsModeratedByAppInstanceUserCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_ListChannelsModeratedByAppInstanceUserCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        Channels: (_) => de_ChannelModeratedByAppInstanceUserSummaryList(_, context),
        NextToken: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
const de_ListChannelsModeratedByAppInstanceUserCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_ListSubChannelsCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_ListSubChannelsCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelArn: __expectString,
        NextToken: __expectString,
        SubChannels: _json,
    });
    Object.assign(contents, doc);
    return contents;
};
const de_ListSubChannelsCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_ListTagsForResourceCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_ListTagsForResourceCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        Tags: _json,
    });
    Object.assign(contents, doc);
    return contents;
};
const de_ListTagsForResourceCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_PutChannelExpirationSettingsCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_PutChannelExpirationSettingsCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelArn: __expectString,
        ExpirationSettings: _json,
    });
    Object.assign(contents, doc);
    return contents;
};
const de_PutChannelExpirationSettingsCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ConflictException":
        case "com.amazonaws.chimesdkmessaging#ConflictException":
            throw await de_ConflictExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_PutChannelMembershipPreferencesCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_PutChannelMembershipPreferencesCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelArn: __expectString,
        Member: _json,
        Preferences: _json,
    });
    Object.assign(contents, doc);
    return contents;
};
const de_PutChannelMembershipPreferencesCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ConflictException":
        case "com.amazonaws.chimesdkmessaging#ConflictException":
            throw await de_ConflictExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_PutMessagingStreamingConfigurationsCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_PutMessagingStreamingConfigurationsCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        StreamingConfigurations: _json,
    });
    Object.assign(contents, doc);
    return contents;
};
const de_PutMessagingStreamingConfigurationsCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ConflictException":
        case "com.amazonaws.chimesdkmessaging#ConflictException":
            throw await de_ConflictExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "NotFoundException":
        case "com.amazonaws.chimesdkmessaging#NotFoundException":
            throw await de_NotFoundExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_RedactChannelMessageCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_RedactChannelMessageCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelArn: __expectString,
        MessageId: __expectString,
        SubChannelId: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
const de_RedactChannelMessageCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ConflictException":
        case "com.amazonaws.chimesdkmessaging#ConflictException":
            throw await de_ConflictExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_SearchChannelsCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_SearchChannelsCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        Channels: (_) => de_ChannelSummaryList(_, context),
        NextToken: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
const de_SearchChannelsCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_SendChannelMessageCommand = async (output, context) => {
    if (output.statusCode !== 201 && output.statusCode >= 300) {
        return de_SendChannelMessageCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelArn: __expectString,
        MessageId: __expectString,
        Status: _json,
        SubChannelId: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
const de_SendChannelMessageCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ConflictException":
        case "com.amazonaws.chimesdkmessaging#ConflictException":
            throw await de_ConflictExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_TagResourceCommand = async (output, context) => {
    if (output.statusCode !== 204 && output.statusCode >= 300) {
        return de_TagResourceCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    await collectBody(output.body, context);
    return contents;
};
const de_TagResourceCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "ResourceLimitExceededException":
        case "com.amazonaws.chimesdkmessaging#ResourceLimitExceededException":
            throw await de_ResourceLimitExceededExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_UntagResourceCommand = async (output, context) => {
    if (output.statusCode !== 204 && output.statusCode >= 300) {
        return de_UntagResourceCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    await collectBody(output.body, context);
    return contents;
};
const de_UntagResourceCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_UpdateChannelCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_UpdateChannelCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelArn: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
const de_UpdateChannelCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ConflictException":
        case "com.amazonaws.chimesdkmessaging#ConflictException":
            throw await de_ConflictExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_UpdateChannelFlowCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_UpdateChannelFlowCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelFlowArn: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
const de_UpdateChannelFlowCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ConflictException":
        case "com.amazonaws.chimesdkmessaging#ConflictException":
            throw await de_ConflictExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_UpdateChannelMessageCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_UpdateChannelMessageCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelArn: __expectString,
        MessageId: __expectString,
        Status: _json,
        SubChannelId: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
const de_UpdateChannelMessageCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ConflictException":
        case "com.amazonaws.chimesdkmessaging#ConflictException":
            throw await de_ConflictExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
export const de_UpdateChannelReadMarkerCommand = async (output, context) => {
    if (output.statusCode !== 200 && output.statusCode >= 300) {
        return de_UpdateChannelReadMarkerCommandError(output, context);
    }
    const contents = map({
        $metadata: deserializeMetadata(output),
    });
    const data = __expectNonNull(__expectObject(await parseBody(output.body, context)), "body");
    const doc = take(data, {
        ChannelArn: __expectString,
    });
    Object.assign(contents, doc);
    return contents;
};
const de_UpdateChannelReadMarkerCommandError = async (output, context) => {
    const parsedOutput = {
        ...output,
        body: await parseErrorBody(output.body, context),
    };
    const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
    switch (errorCode) {
        case "BadRequestException":
        case "com.amazonaws.chimesdkmessaging#BadRequestException":
            throw await de_BadRequestExceptionRes(parsedOutput, context);
        case "ConflictException":
        case "com.amazonaws.chimesdkmessaging#ConflictException":
            throw await de_ConflictExceptionRes(parsedOutput, context);
        case "ForbiddenException":
        case "com.amazonaws.chimesdkmessaging#ForbiddenException":
            throw await de_ForbiddenExceptionRes(parsedOutput, context);
        case "ServiceFailureException":
        case "com.amazonaws.chimesdkmessaging#ServiceFailureException":
            throw await de_ServiceFailureExceptionRes(parsedOutput, context);
        case "ServiceUnavailableException":
        case "com.amazonaws.chimesdkmessaging#ServiceUnavailableException":
            throw await de_ServiceUnavailableExceptionRes(parsedOutput, context);
        case "ThrottledClientException":
        case "com.amazonaws.chimesdkmessaging#ThrottledClientException":
            throw await de_ThrottledClientExceptionRes(parsedOutput, context);
        case "UnauthorizedClientException":
        case "com.amazonaws.chimesdkmessaging#UnauthorizedClientException":
            throw await de_UnauthorizedClientExceptionRes(parsedOutput, context);
        default:
            const parsedBody = parsedOutput.body;
            return throwDefaultError({
                output,
                parsedBody,
                errorCode,
            });
    }
};
const throwDefaultError = withBaseException(__BaseException);
const de_BadRequestExceptionRes = async (parsedOutput, context) => {
    const contents = map({});
    const data = parsedOutput.body;
    const doc = take(data, {
        Code: __expectString,
        Message: __expectString,
    });
    Object.assign(contents, doc);
    const exception = new BadRequestException({
        $metadata: deserializeMetadata(parsedOutput),
        ...contents,
    });
    return __decorateServiceException(exception, parsedOutput.body);
};
const de_ConflictExceptionRes = async (parsedOutput, context) => {
    const contents = map({});
    const data = parsedOutput.body;
    const doc = take(data, {
        Code: __expectString,
        Message: __expectString,
    });
    Object.assign(contents, doc);
    const exception = new ConflictException({
        $metadata: deserializeMetadata(parsedOutput),
        ...contents,
    });
    return __decorateServiceException(exception, parsedOutput.body);
};
const de_ForbiddenExceptionRes = async (parsedOutput, context) => {
    const contents = map({});
    const data = parsedOutput.body;
    const doc = take(data, {
        Code: __expectString,
        Message: __expectString,
    });
    Object.assign(contents, doc);
    const exception = new ForbiddenException({
        $metadata: deserializeMetadata(parsedOutput),
        ...contents,
    });
    return __decorateServiceException(exception, parsedOutput.body);
};
const de_NotFoundExceptionRes = async (parsedOutput, context) => {
    const contents = map({});
    const data = parsedOutput.body;
    const doc = take(data, {
        Code: __expectString,
        Message: __expectString,
    });
    Object.assign(contents, doc);
    const exception = new NotFoundException({
        $metadata: deserializeMetadata(parsedOutput),
        ...contents,
    });
    return __decorateServiceException(exception, parsedOutput.body);
};
const de_ResourceLimitExceededExceptionRes = async (parsedOutput, context) => {
    const contents = map({});
    const data = parsedOutput.body;
    const doc = take(data, {
        Code: __expectString,
        Message: __expectString,
    });
    Object.assign(contents, doc);
    const exception = new ResourceLimitExceededException({
        $metadata: deserializeMetadata(parsedOutput),
        ...contents,
    });
    return __decorateServiceException(exception, parsedOutput.body);
};
const de_ServiceFailureExceptionRes = async (parsedOutput, context) => {
    const contents = map({});
    const data = parsedOutput.body;
    const doc = take(data, {
        Code: __expectString,
        Message: __expectString,
    });
    Object.assign(contents, doc);
    const exception = new ServiceFailureException({
        $metadata: deserializeMetadata(parsedOutput),
        ...contents,
    });
    return __decorateServiceException(exception, parsedOutput.body);
};
const de_ServiceUnavailableExceptionRes = async (parsedOutput, context) => {
    const contents = map({});
    const data = parsedOutput.body;
    const doc = take(data, {
        Code: __expectString,
        Message: __expectString,
    });
    Object.assign(contents, doc);
    const exception = new ServiceUnavailableException({
        $metadata: deserializeMetadata(parsedOutput),
        ...contents,
    });
    return __decorateServiceException(exception, parsedOutput.body);
};
const de_ThrottledClientExceptionRes = async (parsedOutput, context) => {
    const contents = map({});
    const data = parsedOutput.body;
    const doc = take(data, {
        Code: __expectString,
        Message: __expectString,
    });
    Object.assign(contents, doc);
    const exception = new ThrottledClientException({
        $metadata: deserializeMetadata(parsedOutput),
        ...contents,
    });
    return __decorateServiceException(exception, parsedOutput.body);
};
const de_UnauthorizedClientExceptionRes = async (parsedOutput, context) => {
    const contents = map({});
    const data = parsedOutput.body;
    const doc = take(data, {
        Code: __expectString,
        Message: __expectString,
    });
    Object.assign(contents, doc);
    const exception = new UnauthorizedClientException({
        $metadata: deserializeMetadata(parsedOutput),
        ...contents,
    });
    return __decorateServiceException(exception, parsedOutput.body);
};
const de_AppInstanceUserMembershipSummary = (output, context) => {
    return take(output, {
        ReadMarkerTimestamp: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        SubChannelId: __expectString,
        Type: __expectString,
    });
};
const de_Channel = (output, context) => {
    return take(output, {
        ChannelArn: __expectString,
        ChannelFlowArn: __expectString,
        CreatedBy: _json,
        CreatedTimestamp: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        ElasticChannelConfiguration: _json,
        ExpirationSettings: _json,
        LastMessageTimestamp: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        LastUpdatedTimestamp: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        Metadata: __expectString,
        Mode: __expectString,
        Name: __expectString,
        Privacy: __expectString,
    });
};
const de_ChannelBan = (output, context) => {
    return take(output, {
        ChannelArn: __expectString,
        CreatedBy: _json,
        CreatedTimestamp: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        Member: _json,
    });
};
const de_ChannelFlow = (output, context) => {
    return take(output, {
        ChannelFlowArn: __expectString,
        CreatedTimestamp: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        LastUpdatedTimestamp: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        Name: __expectString,
        Processors: _json,
    });
};
const de_ChannelMembership = (output, context) => {
    return take(output, {
        ChannelArn: __expectString,
        CreatedTimestamp: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        InvitedBy: _json,
        LastUpdatedTimestamp: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        Member: _json,
        SubChannelId: __expectString,
        Type: __expectString,
    });
};
const de_ChannelMembershipForAppInstanceUserSummary = (output, context) => {
    return take(output, {
        AppInstanceUserMembershipSummary: (_) => de_AppInstanceUserMembershipSummary(_, context),
        ChannelSummary: (_) => de_ChannelSummary(_, context),
    });
};
const de_ChannelMembershipForAppInstanceUserSummaryList = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_ChannelMembershipForAppInstanceUserSummary(entry, context);
    });
    return retVal;
};
const de_ChannelMessage = (output, context) => {
    return take(output, {
        ChannelArn: __expectString,
        Content: __expectString,
        ContentType: __expectString,
        CreatedTimestamp: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        LastEditedTimestamp: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        LastUpdatedTimestamp: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        MessageAttributes: _json,
        MessageId: __expectString,
        Metadata: __expectString,
        Persistence: __expectString,
        Redacted: __expectBoolean,
        Sender: _json,
        Status: _json,
        SubChannelId: __expectString,
        Type: __expectString,
    });
};
const de_ChannelMessageSummary = (output, context) => {
    return take(output, {
        Content: __expectString,
        ContentType: __expectString,
        CreatedTimestamp: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        LastEditedTimestamp: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        LastUpdatedTimestamp: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        MessageAttributes: _json,
        MessageId: __expectString,
        Metadata: __expectString,
        Redacted: __expectBoolean,
        Sender: _json,
        Status: _json,
        Type: __expectString,
    });
};
const de_ChannelMessageSummaryList = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_ChannelMessageSummary(entry, context);
    });
    return retVal;
};
const de_ChannelModeratedByAppInstanceUserSummary = (output, context) => {
    return take(output, {
        ChannelSummary: (_) => de_ChannelSummary(_, context),
    });
};
const de_ChannelModeratedByAppInstanceUserSummaryList = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_ChannelModeratedByAppInstanceUserSummary(entry, context);
    });
    return retVal;
};
const de_ChannelModerator = (output, context) => {
    return take(output, {
        ChannelArn: __expectString,
        CreatedBy: _json,
        CreatedTimestamp: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        Moderator: _json,
    });
};
const de_ChannelSummary = (output, context) => {
    return take(output, {
        ChannelArn: __expectString,
        LastMessageTimestamp: (_) => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
        Metadata: __expectString,
        Mode: __expectString,
        Name: __expectString,
        Privacy: __expectString,
    });
};
const de_ChannelSummaryList = (output, context) => {
    const retVal = (output || [])
        .filter((e) => e != null)
        .map((entry) => {
        return de_ChannelSummary(entry, context);
    });
    return retVal;
};
const deserializeMetadata = (output) => ({
    httpStatusCode: output.statusCode,
    requestId: output.headers["x-amzn-requestid"] ?? output.headers["x-amzn-request-id"] ?? output.headers["x-amz-request-id"],
    extendedRequestId: output.headers["x-amz-id-2"],
    cfId: output.headers["x-amz-cf-id"],
});
const collectBody = (streamBody = new Uint8Array(), context) => {
    if (streamBody instanceof Uint8Array) {
        return Promise.resolve(streamBody);
    }
    return context.streamCollector(streamBody) || Promise.resolve(new Uint8Array());
};
const collectBodyString = (streamBody, context) => collectBody(streamBody, context).then((body) => context.utf8Encoder(body));
const isSerializableHeaderValue = (value) => value !== undefined &&
    value !== null &&
    value !== "" &&
    (!Object.getOwnPropertyNames(value).includes("length") || value.length != 0) &&
    (!Object.getOwnPropertyNames(value).includes("size") || value.size != 0);
const parseBody = (streamBody, context) => collectBodyString(streamBody, context).then((encoded) => {
    if (encoded.length) {
        return JSON.parse(encoded);
    }
    return {};
});
const parseErrorBody = async (errorBody, context) => {
    const value = await parseBody(errorBody, context);
    value.message = value.message ?? value.Message;
    return value;
};
const loadRestJsonErrorCode = (output, data) => {
    const findKey = (object, key) => Object.keys(object).find((k) => k.toLowerCase() === key.toLowerCase());
    const sanitizeErrorCode = (rawValue) => {
        let cleanValue = rawValue;
        if (typeof cleanValue === "number") {
            cleanValue = cleanValue.toString();
        }
        if (cleanValue.indexOf(",") >= 0) {
            cleanValue = cleanValue.split(",")[0];
        }
        if (cleanValue.indexOf(":") >= 0) {
            cleanValue = cleanValue.split(":")[0];
        }
        if (cleanValue.indexOf("#") >= 0) {
            cleanValue = cleanValue.split("#")[1];
        }
        return cleanValue;
    };
    const headerKey = findKey(output.headers, "x-amzn-errortype");
    if (headerKey !== undefined) {
        return sanitizeErrorCode(output.headers[headerKey]);
    }
    if (data.code !== undefined) {
        return sanitizeErrorCode(data.code);
    }
    if (data["__type"] !== undefined) {
        return sanitizeErrorCode(data["__type"]);
    }
};
